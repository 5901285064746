import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import TopArea from "vcard/TopArea.js";
import Features from "vcard/Features.js";
import Pricing from "vcard/Pricing.js";
import WhyChoose from "vcard/WhyChoose";
import HowItWorks from "vcard/HowItWorks";
import Feedback from "vcard/Feedback";
import FAQ from "vcard/FAQ.js";
import ContactUs from "vcard/ContactUs.js";
import BookASuccess from "vcard/BookASuccess.js";
import Footer from "vcard/Footer.js";


export default () => {
  return (
    <AnimationRevealPage>
      <TopArea />
      <Features />
      <Pricing />
      <WhyChoose />
      <HowItWorks />
      <Feedback />
      <FAQ />
      <ContactUs />
      <BookASuccess />
      <Footer />
    </AnimationRevealPage>
  );
}
