import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container as ContainerBase, ContentWithPaddingXl as ContentBase } from "components/misc/Layouts.js";
import checkboxCircleIconImage from "images/check-circle.png";
import Card01IconImage from "images/c01.png";
import Card02IconImage from "images/c02s.png";
import Card03IconImage from "images/c03.png";
import { ReactComponent as SvgDecoratorBlob } from "images/vcc-logo-gradient.svg";

const Container = tw(ContainerBase)`bg-vcc-900 text-gray-100 -mx-8 px-8`;
const ContentWithPaddingXl = tw(ContentBase)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full text-transparent bg-clip-text bg-gradient-to-r from-vcc-300 via-vcc-400 to-vcc-500`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`w-full lg:text-lg xl:text-xl font-bold uppercase tracking-wider mr-3 text-center`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`w-full text-lg font-bold leading-none text-center`}
      .bigText {
        ${tw`text-5xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 flex-shrink-0`}
    }
    img {
      ${tw`w-6 h-6 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3 text-vcc-black2`}
    }
    .inline-block {
      display: inline-block
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300 bg-transparent bg-gradient-to-r from-vcc-500 to-vcc-300/75 hocus:bg-vcc-300`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 h-64 opacity-100 fill-current mr-6`}
`;

export default ({
  subheading = "",
  heading = "Innovative Pricing",
  description = "",
  plans = null,
  primaryButtonText = "Touch Now"
}) => {
  const defaultPlans = [
    {
      name: "PVC Plastic vCard",
      iconImg: Card01IconImage,
      price: ["£25", ".68"],
      oldPrice: "",
      description: "",
      features: [
        "Custom designed", 
        "Easy To Edit Profile", 
        "Available In Different Colours", 
        "NTAG216", 
        "Works With Apple & Android", 
        "Data Secured On AWS"
      ],
      url: "#"
    },
    {
      name: "Matt vCard",
      iconImg: Card02IconImage,
      price: ["£35", ".68"],
      oldPrice: "",
      description: "",
      features: [
        "Custom designed", 
        "Easy To Edit Profile", 
        "Available In Different Colours", 
        "NTAG216", 
        "Works With Apple & Android", 
        "Data Secured On AWS",
        "Premium Matte UV Finish"
      ],
      url: "#",
      featured: "Most Popular"
    },
    {
      name: "Bamboo vCard",
      iconImg: Card03IconImage,
      price: ["£39", ".68"],
      oldPrice: "",
      description: "",
      features: [
        "Custom designed", 
        "Easy To Edit Profile", 
        "Available In Different Colours", 
        "NTAG216", 
        "Works With Apple & Android", 
        "Data Secured On AWS",
        "Eco Friendly"
      ],
      url: "#"
    }
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                {/* {plan.featured && <span className="featuredText">{plan.featured}</span>} */}
                <span>
                  <img src={plan.iconImg} alt="" />
                </span>
                <span className="nameAndFeaturedContainer">
                  <span className="name">{plan.name}</span>
                </span>
                <div className="pricingContainer">
                  <span className="currentPrice">
                    <span className="bigText">{plan.price[0]}</span>
                    {plan.price[1]}{" "}
                  </span>
                  {plan.oldPrice && <span className="oldPrice">{plan.oldPrice}</span>}
                </div>
                <p className="description">{plan.description}</p>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <span>
                      <img className="inline-block" src={checkboxCircleIconImage} alt="check"/>
                      <span className="text">{feature}</span>
                    </span>
                  </li>
                ))}
              </PlanFeatures>
              <PlanAction>
                <ActionButton as="a" href={plan.url}>
                  {primaryButtonText}
                </ActionButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <WhiteBackgroundOverlay />
      <DecoratorBlob />
    </Container>
  );
};
