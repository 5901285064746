import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg"
import { ReactComponent as SvgDecoratorBlob } from "images/vcc-contact-us-bg.svg";

// Amplify libraries
import Amplify, { API } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-vcc-900 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-4xl sm:text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-vcc-400 to-vcc-500`}
  }
  h3 {
    ${tw`text-2xl sm:text-2xl font-bold text-vcc-white1 mt-6 mb-56`}
  }
  h4 {
    ${tw`text-vcc-white1 mt-2 -mb-8`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-vcc-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`block text-center text-sm font-semibold tracking-wider ip_13m:w-14_6_r ip_13p:w-15_5_r ip_13pm:w-17_8_r ip_11pm:w-17_r sm:w-30_2_r md:w-36_2_r lg:w-21_8_r xl:w-23_4_r text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300 bg-transparent bg-gradient-to-r from-vcc-500 to-vcc-300/75 hocus:bg-vcc-300`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-100 text-vcc-400 fill-current w-24`
const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none absolute top-0 left-0 w-full opacity-100 fill-current`}
`;

class ContactUs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contactUsName: '',
      contactUsEmail: '',
      contactUsMessage: '',
      showInputsWarningMessage: false,
      showThanksForFeedbackMessage: false,
    };

    this.handleInputChange  = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleInputChange (event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleFormSubmit(event) {
    event.preventDefault();

    if (this.state.contactUsName.trim() === '' || this.state.contactUsEmail.trim() === '' || this.state.contactUsMessage.trim() === '') {
      this.setState({showInputsWarningMessage: true});
      return;
    }
    
    this.setState({showInputsWarningMessage: false});
    this.setState({showThanksForFeedbackMessage: true});

    this.invokeApiSendEmailContactUs();
  }

  async invokeApiSendEmailContactUs() {
    const apiName = 'vccSendEmailContactUs';
    const path = '/contact-us';
    const init = {
      headers: {}, // OPTIONAL
      body: {
        name: this.state.contactUsName,
        email: this.state.contactUsEmail,
        message: this.state.contactUsMessage,
      }
    };

    return await API.post(apiName, path, init)
      .then(response => {
        console.log('ContactUs email has been sent successfully!');
      })
      .catch(error => {
        console.log(error.response);
      });;
  }

  render() {
    return (
      <Container>
        <Content>
          <FormContainer>
            <div tw="mx-auto max-w-4xl z-10" style={{position: 'relative'}}>
              <h2>Contact Us</h2>
              {this.state.showInputsWarningMessage && <h4 style={{color: 'orangered'}}>Please complete all required fields!</h4>}
              {this.state.showThanksForFeedbackMessage && <h3>Thank you for your feedback, we will get back to you soon!</h3>}
              {!this.state.showThanksForFeedbackMessage && 
              <form action="#" onSubmit={this.handleFormSubmit}>
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Your Name</Label>
                      <Input id="name-input" type="text" name="contactUsName" placeholder="e.g. John Doe" value={this.state.contactUsName} onChange={this.handleInputChange}/>
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Your Email Address</Label>
                      <Input id="email-input" type="email" name="contactUsEmail" placeholder="e.g. john@mail.com" value={this.state.contactUsEmail} onChange={this.handleInputChange}/>
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer tw="flex-1">
                      <Label htmlFor="name-input">Your Message</Label>
                      <TextArea id="message-input" name="contactUsMessage" placeholder="e.g. Details about your question" value={this.state.contactUsMessage} onChange={this.handleInputChange}/>
                    </InputContainer>
                  </Column>
                </TwoColumn>

                <SubmitButton type="submit" value="Submit">Submit</SubmitButton>
              </form>}
            </div>
            <DecoratorBlob />
            <SvgDotPattern1 />
          </FormContainer>
        </Content>
      </Container>
    );
  }
};

export default ContactUs;
