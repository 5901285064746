import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import FAQIconImage from "images/faq.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left text-transparent bg-clip-text bg-gradient-to-r from-vcc-500 to-vcc-300`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-vcc-400 text-gray-100 p-1 rounded-full group-hover:bg-vcc-300 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Questions",
  description = "",
  imageSrc = FAQIconImage,
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Do vCards work with my phone?",
      answer:
        "If you have a smartphone, the answer is almost certainly yes. All iPhones’ models XR and later (September 2018) and about 90% of the Android phones have NFC enabled. Additionally, as a backup, all smartphones, both Apple and Android, have a built-in QR code scanner. Simply open up the camera and point it at the QR code located on the back of every vCardCampus."
    },
    {
      question: "Is the card compatible with every mobile device?",
      answer:
        "Yes, there are two ways the vCardCampus transfers your details using a contactless method.<br/> They are:<br/> 1. Using NFC (near field communication) just like your bank card. Once an active NFC card is presented to an NFC compatible device, a prompt will display on screen, once the prompt is followed your digital business card will display.<br/> 2. QR codes. Qr codes are very commonly used and allows us to ensure that every device can read your business card even if the device does not have NFC capabilities. Any device with a camera can read the QR code to trigger the prompt to open your digital business card."
    },
    {
      question: "How do vCards work?",
      answer:
        "Every vCards has a chip inside that wirelessly sends your information to a phone. When you tap the card on a compatible phone, a contact field appears with all of your information automatically pre-populated. The owner of the phone just has to hit “save”. Every vCard also contains a QR code on the back to ensure compatibility with older smartphone devices that do not have NFC compatibility."
    },
    {
      question: "Where do I add my logo/design?",
      answer:
        "Once purchased, check your emails for a design brief from one of our designers. Here our designer will take your brief and create your design. You will then approve the design prior going to print for overall satisfaction."
    },
    {
      question: "What details can my card share?",
      answer:
        "Your Name<br/>Company Name<br/>Job Title<br/>Business address<br/>Business email<br/>Landline<br/>Mobile number<br/>Business Bio<br/>Website<br/>Social links for Facebook, Instagram, LinkedIn, Youtube,…"
    },
    {
      question: "Can I update the information on my vCard?",
      answer:
        "Yes. After we ship your card, you will receive an automated email with login instructions. In your account dashboard, you will be able to update your contact information whenever you’d like."
    },
    {
      question: "Do smartphones need an app to use this technology?",
      answer:
        "No, it's as simple as tap and go. As this technology is similar to Apple and Android Pay, 9/10 phones are now compatible with all of vCardCampus features."
    },
    {
      question: "Is vCardCampus secure?",
      answer:
        "vCardCampus is as secure as any new-age technology and as safe as the information you put on it. It only works within an inch of a compatible device, and can only share, rather than extract information. In addition, there is no financial information stored. All details shared are typically details you will have on any of your traditional business cards."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden sm:block xs:w-52/100 c1:w-52/100 xl:w-5/12 flex-shrink-0">
            <Image imageSrc={imageSrc} style={{ marginTop: '6rem' }} />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
