import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "images/f01.png";

import PurchaseIconImage from "images/hiw01.png";
import DesignIconImage from "images/hiw02.png";
import ReviewIconImage from "images/hiw03.png";
import TSGIconImage from "images/hiw04.png";
import { ReactComponent as SvgDecoratorBlob } from "images/waves.svg";

const Container = tw.div`relative -mx-8 px-8 text-gray-100 bg-vcc-900`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full text-transparent bg-clip-text bg-gradient-to-r from-vcc-300 via-vcc-400 to-vcc-500`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`w-full text-center rounded-full p-5 flex-shrink-0 flex flex-col items-center`}
    img {
      ${tw`h-16`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none text-vcc-black1 text-center text-transparent bg-clip-text bg-gradient-to-br from-vcc-500/100 to-vcc-400/100 hocus:text-vcc-500/50`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug text-vcc-white1`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none absolute right-0 top-0 w-full opacity-5 fill-current mr-6`}
`;

export default ({
  cards = null,
  heading = "How it works"
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: PurchaseIconImage, title: "Purchase Your vCard", description: "After placing your order, you will receive instructions on how to provide us with your logo or design for on the card." },
    { imageSrc: DesignIconImage, title: "Create Your Design", description: "Provide design instructions and upload your logo(s)." },
    { imageSrc: ReviewIconImage, title: "Review design proof", description: "Review the digital print proof and provide feedback. After your approval we will print and ship your card." },
    { imageSrc: TSGIconImage, title: "Tap, Share & Go", description: "It's that simple! Share who you are and what you do with with one quick tap." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
