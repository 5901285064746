import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "images/f01.png";

import ImpressionIconImage from "images/wcv01.png";
import SafetyIconImage from "images/wcv02.png";
import SecurityIconImage from "images/wcv03.png";

const Container = tw.div`relative -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full text-vcc-black1`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`w-full text-center rounded-full p-5 flex-shrink-0 flex flex-col items-center`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none text-vcc-black1 text-center`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug text-gray-600`}
  }
`;

export default ({
  cards = null,
  heading1 = "Why choose",
  heading2 = "vCardCampus"
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: ImpressionIconImage, title: "Impression", description: "Share customised business profiles with a single tap." },
    { imageSrc: SafetyIconImage, title: "Safety", description: "Reduce the risk of spreading harmful bacteria and germs with contactless technology data." },
    { imageSrc: SecurityIconImage, title: "Security", description: "Data can be encrypted onto your own systems and the cards are encoded with  unique data." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading1}</Heading>
        <Heading>{heading2}</Heading>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
