import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import vccIconImage from "images/f02.png";
import p2pIconImage from "images/f01.png";
import socialIconImage from "images/f03.png";
import cloudIconImage from "images/f05.png";
import mobileOsIconImage from "images/f04.png";
import secureIconImage from "images/f06.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full text-transparent bg-clip-text bg-gradient-to-r from-vcc-300 to-vcc-500`;
const Description = tw(SectionDescription)`w-full text-center text-vcc-gray2`;
const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`h-12`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none text-vcc-black1`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose text-vcc-gray2`}
  }
`;

export default ({ cards = null, heading = "Impressive Features", description = "NFC technology (Near Field Communication) allows two devices to communicate over a small distance." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: p2pIconImage, title: "One vCardCampus ", description: "Tap your card on a phone and share your contact details without contact."},
    { imageSrc: vccIconImage, title: "Plug & Play", description: "For both sharing and receiving your contact details, no app is needed." },
    { imageSrc: socialIconImage, title: "Get social", description: "Connect your social accounts and connect with your audience." },
    { imageSrc: mobileOsIconImage, title: "Update in Real Time", description: "Save time and money by not having to buy more business cards every time your details change." },
    { imageSrc: cloudIconImage, title: "Device Support", description: "Works on all smartphones running both Android or iOS." },
    { imageSrc: secureIconImage, title: "Secure Data", description: "Amazon AWS is architected to be the most flexible and secure cloud computing environment available today." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
