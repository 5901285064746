import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import mockupImageSrc from "images/bas-bg.png"
import { ContentWithPaddingXl, Container as ContainerBase } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

const Container = tw(ContainerBase)`-mx-8`
const Content = tw(ContentWithPaddingXl)``
const Row = tw.div`px-8 flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left justify-center mt-32`;

const ColumnContainer = tw.div`max-w-2xl ml-0`
const TextContainer = tw(ColumnContainer)``;
const Text = tw(SectionHeading)`text-gray-100 lg:text-left max-w-none text-3xl leading-snug font-medium text-vcc-black2`;
const Subheading = tw(SubheadingBase)`text-5xl sm:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-vcc-500 to-vcc-400 tracking-wider`

const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`
const Link = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold tracking-wider rounded inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0   shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300 border-2 hocus:border-2 border-vcc-400 hocus:border-vcc-300 bg-gradient-to-r hover:from-vcc-500 hover:to-vcc-400`}
  span {
    ${tw`leading-none inline-block text-vcc-black2`}
  }
`;

const ImageContainer = tw(ColumnContainer)`mt-16 lg:mt-0 lg:ml-0 flex justify-end w-full lg:h-128 lg:-mb-9_6 xxs:h-96 xxs:-mb-7_7`;


export default ({
  subheading = "Touch To Succeed",
  text = "A professional way to connect by an innovative business vCard.",
  link1Text = "Book a Success",
  link1Url = "#",
  pushDownFooter = false,
  imageSrc = mockupImageSrc,
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <Content>
        <Row>
          <TextContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Text>{text}</Text>
            <LinksContainer>
              <Link href={link1Url}>
                <span>{link1Text}</span>
              </Link>
            </LinksContainer>
          </TextContainer>
          <ImageContainer>
            <img src={imageSrc} alt="" />
          </ImageContainer>
        </Row>
      </Content>
    </Container>
  );
};
